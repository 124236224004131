<template>
	<div class="top-card-container">
		<div class="top-card garage">
			<h3 class="top-card-title">My Garage</h3>

			<div class="top-card-garage">
				<div
					v-if="storedRide !== null"
					class="with-stored-ride"
				>
					<ul class="ride-list">
						<template v-if="storedRide.values">
							<li
								v-for="value in filterValues"
								:key="Object.keys(value)[0]"
							>
								<strong>{{ Object.keys(value)[0] }}: </strong>
								{{ Object.values(value)[0] }}
							</li>
						</template>
						<template v-else>
							<li>
								<strong>Make:&nbsp;</strong>
								{{ storedRide.make }}
							</li>
							<li v-if="storedRide.type">
								<strong>Type:&nbsp;</strong>
								{{ storedRide.type }}
							</li>
							<li>
								<strong>Year:&nbsp;</strong>
								{{ storedRide.year }}
							</li>
							<li>
								<strong>Model:&nbsp;</strong>
								{{ storedRide.model }}
							</li>
						</template>
					</ul>
				</div>

				<div
					class="without-stored-ride"
					v-else
				>
					Add or Select a Ride so you know your parts fit.
					<div class="rides">
						<button
							v-for="(ride, index) in localGarageFiltered.slice(0, 5)"
							:key="'ride_' + index"
							class="ride-button"
							@click="setRide(ride)"
							@mouseenter="captureEvent('garageCardHover', 'Garage Card Hover', ride)"
						>
							{{ ride.name ? ride.name : `${ride.year} ${ride.make} ${ride.model}` }}
						</button>
					</div>
				</div>

				<div class="open-garage">
					<button
						class="btn btn-default btn-sm open-garage-button"
						@click="toggleGarage"
					>
						{{ buttonLabel }}
					</button>
					<!-- <button class="btn btn-primary btn-sm" style="text-transform: uppercase; font-weight: 700;">Shop OEM</button> -->
				</div>
			</div>

			<div class="top-card-bottom">
				<div class="shop-more">
					<a :href="shopLink" @click.prevent="visitLink">
						Shop OEM
						<span class="glyphicon glyphicon-menu-right"></span>
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Garage from '@/mixins/garage.js';
import Tracking from '@/mixins/event-tracking';

export default {
	name: 'garage-card',
	mixins: [Garage, Tracking],
	props: {
		auth: { default: 0 },
		site: { default: 'pz' },
	},
	mounted() {
		this.checkForStoredRide();

		if (this.hasStoredRide) {
			this.getStoredRide();
		}

		if (this.auth) {
			this.getBackendGarage();
		} else {
			this.getLocalGarage();
		}
	},
	computed: {
		buttonLabel() {
			if (this.storedRide !== null) return 'Edit or Change';

			return 'Add Ride';
		},
		shopLink() {
			if (this.storedRide !== null && this.garageFitmentId !== '')
				return `/catalog/v1/${this.garageFitmentId}/${this.storedRideName.replaceAll(' ', '-').replaceAll('/', '_')}?use_fitment=1`;

			return '/catalog';
		},
		filterValues() {
			if (!this.storedRide || !this.storedRide.values) return [];
			// exclude base models, trims, vehicle display names
			const names = ['Base Models', 'Trims', 'Vehicle Display Names'];
			return this.storedRide.values
				.filter((value) => {
					return (
						!Object.keys(value).some((key) => names.includes(key)) && this.hasValue(Object.values(value)[0])
					);
				})
				.map((value) => this.formatValue(value));
		},
	},
	methods: {
		toggleGarage() {
			this.captureEvent('garageCardToggleGarage', 'Garage Card Toggle Garage');
			window.root.openGarageMenu();
		},
		visitLink() {
			this.captureEvent('garageCardShopLink', 'Garage Card Shop Link');
			window.location.href = this.shopLink;
		},
		captureEvent(event, eventLabel, ride = null) {
			const options = {
				eventCategory: 'Garage Card',
				eventLabel,
				rideName: ride ? ride.name : this.storedRideName,
				rideId: ride ? ride.id : this.garageFitmentId,
			};
			if (ride) {
				options.type = ride.type;
			}
			this.trackEvent(event, options);
		},
		setRide(ride) {
			this.captureEvent('garageCardSetRide', 'Garage Card Set Ride', ride);
			this.setStoredRide(ride);
			this.updateSelected(ride);
		},
		async updateSelected(ride = null) {
			const id = this.getRideFitmentId(ride);
			const options = {
				method: 'POST',
			};
			const url = new URL(`/garage/update/${id}`, location);
			const request = new Request(url, options);
			await fetch(request).then(() => {
				window.location.reload();
			});
		},
		hasValue(value) {
			return value !== null && value !== '' && typeof value !== 'undefined' && value.length;
		},
		formatValue(value) {
			if (Array.isArray(value)) {
				return value[0];
			} else {
				return value;
			}
		},
	},
};
</script>

<style lang="scss">
div.top-card.garage {
	background-color: #252525;
	background-color: color(display-p3 0.145098 0.145098 0.145098);
	color: #fff;
	color: color(display-p3 1 1 1);
	min-height: 200px;
	height: 100%;
	justify-content: space-between;
	padding: 0 2em;
	width: 100%;

	& * {
		align-self: stretch;
	}

	.without-stored-ride {
		margin-bottom: 4px;
	}

	ul.ride-list {
		list-style: none;
		margin: 0;
		margin-bottom: 1em;
		max-height: calc(200 / var(--default-font-size, 14) * 1rem);
		overflow-y: scroll;
		padding: 0;

		li {
			margin: 0;
		}
	}

	h3.top-card-title {
		color: #fff !important;
		color: color(display-p3 1 1 1);
		text-align: center;
	}

	.top-card-garage {
		width: 100%;
	}

	.open-garage-button {
		font-weight: 700;
		margin-bottom: 6px;
		text-transform: uppercase;
	}

	.open-garage {
		display: flex;
		flex-direction: column;
	}

	div.rides {
		display: flex;
		flex-direction: column;
		max-height: calc(200 / var(--default-font-size, 14) * 1rem);
		overflow-y: scroll;
		padding: 0.5em 0;
		text-align: left;

		button.ride-button {
			background-color: transparent;
			border: none;
			border-left: 2px solid #c3161c;
			border-left: 2px solid color(display-p3 0.764706 0.086275 0.109804);
			font-weight: 700;
			margin-bottom: 3px;
			padding: 3px;
			text-align: left;

			&:hover {
				background-color: #333;
				background-color: color(display-p3 0.2 0.2 0.2);
			}
		}
	}

	div.top-card-bottom {
		div.shop-more {
			border-color: #444;
			border-color: color(display-p3 0.266667 0.266667 0.266667);

			a {
				color: #fff !important;
				color: color(display-p3 1 1 1);

				&:hover {
					color: #888;
					color: color(display-p3 0.533333 0.533333 0.533333);
				}
			}
		}
	}
}
</style>
